<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }} {{ group_name }}</div>
        <div class="row align-items-center">
            <div class="d-flex" style="flex: 1; flex-basis: 95%;">
                <div class="col-4">
                    <label class="p-col-fixed txt-left p-0">Công ty</label>
                    <div class="p-col pl-0">
                        <v-select :clearable="false" title="Mã công ty" id= "a2" :filterable="false" placeholder="Chọn" v-model="selCompany" :options="listCompany" label="code" @search="fetchOptionsCompany">
                            <span slot="no-options" @click="$refs.select.open = false">
                                Không có dữ liệu
                            </span>
                        </v-select>
                        <input class="input_tmp_validator" id="company_id">
                    </div>
                </div>
                <div class="col-4 pl-0">
                    <label class="p-col-fixed txt-left p-0">Bộ phận</label>
                    <div class="p-col pl-0">
                        <v-select :clearable="false" title="Bộ phận" id= "a3" :filterable="false" placeholder="Chọn" v-model="selDepartment" :options="listDepartment" label="name" @search="fetchOptionsDepartment">
                            <span slot="no-options" @click="$refs.select.open = false">
                                Không có dữ liệu
                            </span>
                        </v-select>
                        <input class="input_tmp_validator" id="department_id">
                    </div>
                </div>
                <div class="col-4 pl-0">
                    <label class="p-col-fixed txt-left p-0">Người dùng</label>
                    <div class="p-col pl-0">
                        <v-select :clearable="false" title="Người dùng" id= "a1" :filterable="false" placeholder="Chọn" v-model="selUser" :options="listUser" label="fullname" @search="fetchOptionsUser">
                            <span slot="no-options" @click="$refs.select.open = false">
                                Không có dữ liệu
                            </span>
                        </v-select>
                        <input class="input_tmp_validator" id="user_id">
                    </div>
                </div>
            </div>
            <div style="flex: 1;">
                <label class="p-col-fixed txt-left"></label>
                <div class="p-col pl-0">
                    <Button v-if="this.role.add == true"
                        type="button"
                        icon="pi pi-plus"
                        class="p-button-success"
                        @click="addUser"
                    />
                </div>
            </div>
        </div>
        
        <!--{{listData}}-->
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault="filters"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          @groupName="groupName"
          :companyFilter="true"
          v-on:action-click="onActionClicked"
        ></BaseDatatable> 
        <!-- <Popup
          :key="editDialogKey"
          :show="displayDialog"
          :objKey="editId"
          :objModelName="modelName"
          :mode="dialogMode"
          @onChange="onChange()"
        ></Popup> -->

        <Dialog
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Bạn có chắc muốn xóa <strong>{{this.deleteName}}</strong>
          <template
            #footer
          >
            <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Hủy"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
import gql from "graphql-tag";
import AuthenticationApp from '@/core/AuthenticationApp';
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      options: [],
      filters: {},
      filterOptions: {},
      group_name: null,
      listData: [],
      modelName: '',
      dataModel: null,
      title: 'Phân công',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      listCompany: [],
      listCompanyAll: [],
      selCompany: null,
      
      listDepartment: [],
      listDepartmentAll: [],
      selDepartment: null,

      listUser: [],
      listUserAll: [],
      selUser: null
    }
  },
  mounted () {
    this.initParams();
  }
  ,
  async created() {
    await this.getListUser();
    await this.getListCompany();
    await this.getListDepartment();
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    // this.title = this.dataModel.name
    // console.log("nametabel",this.title)

    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADD_USER_TO_ROLE);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
  },
  watch: {
    '$route': 'initParams',
    async 'selCompany'() {
        if (this?.selCompany) {
            this.$set(this, 'selDepartment', null);
            this.$set(this, 'selUser', null);
            await this.getListUser();
            await this.getListDepartment();
        }
    },
    async 'selDepartment'() {
        if (this?.selDepartment) {
            this.$set(this, 'selUser', null);
            await this.getListUser();
        }
    },
    'selUser'() {
        if (this?.selUser) {
            if (!this?.selUser?.company) {
                this.$set(this, 'selCompany', this?.selUser?.company || null);
            }
            if (!this?.selUser?.department) {
                this.$set(this, 'selDepartment', this?.selUser?.department || null);
            }
        }
    }
  },
  methods: {
    async addUser() {
        try {
            this.$commonFuction.is_loading(true);
            if (this?.selUser?.id) {
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('group_user'),
                    variables: {
                        objects: {"uid": this.selUser.id, "group_id": this.$route.params.id, "created_at": new Date(), "created_by": this.$store.getters.user}
                    }
                }).then(async (res) => {
                    console.log(res);
                    await this.getListUser();
                    await this.getListCompany();
                    await this.getListDepartment();
                    this.initParams();
                    this.$set(this, 'selUser', null);
                    this.$set(this, 'selCompany', null);
                    this.$set(this, 'selDepartment', null);
                    this.$toast.add({
                        severity: 'success',
                        summary: "Thành công",
                        detail: "Thêm người dùng thành công",
                        life: 3000
                    })
                    await this.$commonFuction.is_loading(false);
                }).catch((e)=> {
                    console.log(e);
                    this.$commonFuction.is_loading(false);
                })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: "Vui lòng chọn người dùng để thêm!",
                    life: 3000
                })
                this.$commonFuction.is_loading(false);
            }
        } catch(err) {
            console.log(err);
            this.$commonFuction.is_loading(false);
        }
    },
    async getListUser(){
        var where_key = {
            deleted : {_eq : false},
            _not: {group_users: {group_id: {_eq: `${this.$route.params.id}`}}}
        }
        var listDisplayCompany = AuthenticationApp.getListCompany();
        console.log('moomoo', listDisplayCompany);
        //add constraint to where_key to get company with id in listDisplayCompany
        if (listDisplayCompany != null) {
            where_key.users_companies = {
              company_id: {_in: listDisplayCompany},
              deleted: {_eq: false},
              active: {_eq: true},
            }
        }
        if (this?.selCompany) {
            where_key.users_companies = {
                company_id: {_eq: `${this.selCompany.id}`}
            }
        }
        
        if (this?.selDepartment) {
            where_key.department_id = {_eq: `${this.selDepartment.id}`}
        }
        
        let {data} = await this.$apollo.query({
            query: DataServices.getList('users',{"fields" : `id fullname department {id name} users_companies {company {id name code}}`}),
            variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_company = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_company.push({id : e.id, fullname :e.fullname, department: e.department, company: e?.users_companies ? e?.users_companies[0]?.company : null});
        }
        this.listUser = list_company;
        this.listUserAll = list_company;
    },
        
    async fetchOptionsUser(search, loading){
        console.log(loading);
        if(search !== '' && search !== null){
            var where_key = {
                deleted : {_eq : false},
                _not: {group_users: {group_id: {_eq: `${this.$route.params.id}`}}},
                _or: {
                    fullname: {_iregex: this.$commonFuction.search(search)}
                }
            };
            if (this?.selCompany) {
                where_key.users_companies = {
                    company_id: {_eq: `${this.selCompany.id}`}
                }
            }
            
            if (this?.selDepartment) {
                where_key.department_id = {_eq: `${this.selDepartment.id}`}
            }
            
            let {data} = await this.$apollo.query({
            query: DataServices.getList('users',{"fields" : `id fullname department {id name} users_companies {company {id name code}}`}),
            variables: {
                where_key: where_key,
                orderBy:[{created_at : 'desc'}]
            },
            fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_company = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
            const e = list[i];
                list_company.push({id : e.id, fullname :e.fullname, department: e.department, company: e?.users_companies ? e?.users_companies[0]?.company : null});
            }
            this.listUser = list_company;
        }else{
            // cosnole.log("dasdasd")
            this.listUser = this.listUserAll
        }
    },
    async getListDepartment(){
        var where_key = {
            deleted : {_eq : false},
        }
        if (this?.selCompany) {
            where_key.users = {
                users_companies: {
                    company_id: {_eq: `${this.selCompany.id}`}
                }
            }
        }
        
        let {data} = await this.$apollo.query({
            query: DataServices.getList('department',{"fields" : `id name`}),
            variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_company = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_company.push({id : e.id, name :e.name});
        }
        this.listDepartment = list_company;
        this.listDepartmentAll = list_company;
    },
        
    async fetchOptionsDepartment(search, loading){
        console.log(loading);
        if(search !== '' && search !== null){
            var where_key = {
                deleted : {_eq : false},
                _or: {
                    name: {_iregex: this.$commonFuction.search(search)}
                }
            };
            if (this?.selCompany) {
                where_key.users = {
                    users_companies: {
                        company_id: {_eq: `${this.selCompany.id}`}
                    }
                }
            }
            let {data} = await this.$apollo.query({
            query: DataServices.getList('department',{"fields" : `id name`}),
            variables: {
                where_key: where_key,
                orderBy:[{created_at : 'desc'}]
            },
            fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_company = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_company.push({id : e.id, name :e.name});
            }
            this.listDepartment = list_company;
        }else{
            // cosnole.log("dasdasd")
            this.listDepartment = this.listDepartmentAll
        }
    },
    
    async getListCompany(){
        var where_key = {
            deleted : {_eq : false},
        }
        console.log('maomao');
        var listDisplayCompany = AuthenticationApp.getListCompany();
        console.log('moomoo', listDisplayCompany);
        //add constraint to where_key to get company with id in listDisplayCompany
        if (listDisplayCompany != null) {
            where_key.id = {_in: listDisplayCompany};
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('company',{"fields" : `id code name`}),
            variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_company = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_company.push({id : e.id, name :e.name, code: e.code});
        }
        console.log("momomo", list_company);
        this.listCompany = list_company;
        this.listCompanyAll = list_company;
    },
        
    async fetchOptionsCompany(search, loading){
        console.log(loading);
        if(search !== '' && search !== null){
            var where_key = {
            deleted : {_eq : false},
            _or: {
                code:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"}
            }
            };
            let {data} = await this.$apollo.query({
            query: DataServices.getList('company',{"fields" : `id code name`}),
            variables: {
                where_key: where_key,
                orderBy:[{created_at : 'desc'}]
            },
            fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_company = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_company.push({id : e.id, name :e.name, code: e.code});
            }
            this.listCompany = list_company;
        }else{
            // cosnole.log("dasdasd")
            this.listCompany = this.listCompanyAll
        }
    },
    groupName(data) {
        this.group_name = data;
    },
    //lấy data từ model
    initParams() {
        this.$store.commit('setLoading', true);
        this.modelName = this.$route.meta.model;
        this.dataModel = DataServices.getModel(this.modelName);
        // this.title = this.dataModel.label;
        this.callbackOptions();
    },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      for (let index = 0; index < fields.length; index++) {
        var attributes = [];
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          // console.log("SDFGHJK",element)
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          var where_ = {}
          var result;
          if( element['name']== "service_name"){
            // console.log("element",element)
            let {data} = await this.$apollo.query({
              query: DataServices.getList("service", {"fields" : "id name"}),
              variables: {
                where_key : {
                  deleted : {_eq: false},
                  parent_id : {_is_null: true}
                },
                orderBy:[{ordinal: 'desc'},{created_at : 'desc'}]
              }
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }else{
            if (element.where_option)
              where_ = element.where_option;
            let {data} = await this.$apollo.query({
              query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
              variables: {where_key : where_}
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }
          // console.log("optionsData",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
    //   if (this.role.add) {
    //     actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
    //   }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        // if (that.role.edit) {
        //   buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        // }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        if (that.role.delete) {
          buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data.uid
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    callback(col, item) {
      if (col === 'delete') {
        this.showDeleteDialog = true;
        this.deleteId = item.data.id;
        this.deleteName = item.data.title;
        this.editDialogKey++;
      }
    },
    async deleteData() {
        console.log("ssssssssssssssssssssssss:",this.tableAction);
        var that = this;
        this.$commonFuction.is_loading(true);
        var queryUpdate = `
            mutation delete_group_user {
                delete_group_user (where: { uid: {_eq: "${this.deleteId}"}, group_id: {_eq: "${this.$route.params.id}"}}) {
                    affected_rows
                }
            }
          `;
        this.$apollo.mutate({
            mutation: gql(queryUpdate)
        }).then(() => {
            that.showDeleteDialog = false;
            that.$commonFuction.is_loading(false);
            that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
            that.initParams();
        }).catch(async error => {
            console.error('ERROR: ', error)
            that.$toast.add({
                severity: 'error',
                summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            })
            that.$commonFuction.is_loading(false);
        })
    },
  },
  /*apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        console.log('8*************************',this.modelName);
        return DataServices.getList(this.modelName);
      },
      variables () {
        // Use vue reactive properties here
        return this.whereKeyList;
      },
      update(data) {
        console.log('this.dataModel.tableName',data);
        var listData = data[this.dataModel.tableName]
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(listData)
        this.$store.commit('setLoading', false);
        this.componentKey += 1;
        console.log('data[this.dataModel.tableName]',data[this.dataModel.tableName]);
        return data[this.dataModel.tableName]
      },
      fetchPolicy: 'network-only',
      error() {
        this.$store.commit('setLoading', false);
      },
      skip() {
        return !this.modelName
      }
    }
  }*/

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
</style>